import api from "@/api";
import tableActions from "@/store/templates/table/actions";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";

const fetchCall = api.company.filterLog.fetchAll;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    // OVERRIDE THE DEFAULT
    const companyId = context.rootState.company.model.id;
    return await tableActions.fetch(context, {
      fetchCall: fetchCall.bind(null, companyId)
    });
  }
};
